<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <FirmLeftPanel :data-item='dataItem'></FirmLeftPanel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Taşeron Dosyalar</h3>

					<p class="text-lg">
						Taşeron firma ile ilgili arşivlenmesi gereken dosyaları bu bölümden yükleyebilirsiniz. PDF, JPEG veya PNG formatında dosyalar desteklenmektedir.<br>
                        <br>
                        Örnek olarak kart geçiş sistemleri ile ilgili teslim etmiş olduğunuz kartlar için teslim belgesi var ise bu bölümden yükleyebilirsiniz.
                        <br>
                        <span></span>
                        <span class="text-red-500 font-bold ml-2 mr-2">*</span> işaretli alanlar zorunlu alanlardır.<br>
					</p>

				</Sidebar>

				</span>
            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <FirmTabMenu :firm-id='firmId'></FirmTabMenu>

                <div class='col-12'>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Dosya Tipi</label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id="fileType" v-model="selectedFileType" :options="fileTypes" optionLabel="name" option-value='name' placeholder="Seçim Yapın" @change='fileTypeChanged' ></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 md:col-2 md:mb-0'></label>
                        
                        <div class='col-12 md:col-6'>
                            <FileUpload choose-label="Yükle" mode="basic" name="upload[]" :url="uploadUrl" :auto="true" @before-upload='uploadStarted' @upload="uploadFinished" class="p-button-success"/>
                        </div>

                    </div>

                    <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' filterDisplay='menu' :loading='loadingIndicator' responsiveLayout='scroll'>

                        <template #empty>
                            Dosya bulunamadı.
                        </template>

                        <template #loading>
                            Dosyalar yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column field='insertDate' header='Tarih' filterField='insertDate' :showFilterMatchModes='false' style='width:14rem'>
                            <template #body='{data}'>
                                {{ formatDate(data.insertDate) }}
                            </template>
                        </Column>

                        <Column field='fileType' header='Dosya Tipi' filterField='fileType' :showFilterMatchModes='false'>
                        </Column>

                        <Column field='managerUser.name' header='Yükleyen' filterField='fileType' :showFilterMatchModes='false' style='width:24rem'>
                            <template #body='{data}'>
                                {{ data.managerUser.name + " " + data.managerUser.surName}}
                            </template>
                        </Column>

                        <Column field='FilePath' bodyClass='text-center' header='Görüntüle' filterField='FilePath' :showFilterMatchModes='false' style='width:7.5rem'>
                            <template #body='{data}'>
                                <a :href='"http://image.a1catering.com.tr" + data.filePath' target='_blank'> 
                                    <Button icon='pi pi-eye' class='p-button-rounded p-button-primary'/>
                                </a>
                            </template>
                        </Column>

                        <Column header='Sil' bodyClass='text-center' style='width:7.5rem' :exportable='false'>
                            <template #body='slotProps'>
                                <Button icon='pi pi-trash' class='p-button-rounded p-button-danger' @click='deleteFile(slotProps.data)' />
                            </template>
                        </Column>

                    </DataTable>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import FirmTabMenu from './firmTabMenu';
import FirmLeftPanel from './firmLeftPanel';
import FirmService from '../../services/firmService';
import ProjectFileService from '../../services/projectFileService';
import moment from 'moment';
import { getUserId } from '../common/commonConstantFunctions';
import { getFirm } from '../common/commonFunctions';

export default {
    components: { FirmTabMenu, FirmLeftPanel, AdminToast },
    _firmService: null,
    _projectFileService : null,
    created() {
        this._projectFileService = new ProjectFileService();
        this._firmService = new FirmService();
        this.firmId = this.$route.params.firmId;
        this.userId = getUserId();
        this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/file?fileType=" + this.selectedFileType + "&resourceId=" + this.firmId + "&resourceType=Firm&managerUserId=" + this.userId;
    },
    async mounted() {
        this.dataItem = await getFirm(this.firmId);
        await this.getProjectFileList();
    },
    data() {
        return {
            loadingIndicator:false,
            userId : 0,
            uploadUrl:"",
            selectedFileType:"Sözleşme",
            fileTypes:[
                {
                    name: "Sözleşme",
                    id:1
                },
                {
                    name: "Teslim Tesellüm Tutanağı",
                    id:2
                },
                {
                    name: "Makbuz",
                    id:3
                },
                {
                    name: "Firma Bilgileri",
                    id:4
                },
                {
                    name: "Diğer",
                    id:5
                },
            ],
            dataList: [],
            firmId: 0,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
            },
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._firmService.updateFirm(this.firmId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Firma güncelleme başarılı');
                } else {
                    showErrorMessage(this, 'Firma güncellemede sorun ile karşılaşıldı');
                }
            }
            else {
                showErrorMessage(this, 'Firma güncellemede sorun ile karşılaşıldı');
            }

        },
        validateForm() {
            if (this.dataItem.name == null || this.dataItem.name == '') {
                showValidationMessage(this, 'İsim alanı boş bırakılamaz');
                return false;
            }

            return true;
        },

        async getProjectFileList() {
            let fileResponse = await this._projectFileService.getAllProjectFiles(this.firmId, "Firm");
            if (fileResponse.isSuccess) {
                this.dataList = fileResponse.data;
            }
        },
        fileTypeChanged() {
            this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/file?fileType=" + this.selectedFileType + "&resourceId=" + this.firmId + "&resourceType=Firm&managerUserId=" + this.userId;
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },

        async uploadFinished() {
            showSuccessMessage(this, "Dosya Yükleme İşlemi Başarılı")
            await this.getProjectFileList()
            this.loadingIndicator = false;
        },
        uploadStarted() {
            this.loadingIndicator = true;
        },

        deleteFile(item) {
            this.$confirm.require({
                message: 'Dosyayı Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let deleteResponse = await this._projectFileService.deleteProjectFile(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getProjectFileList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                }
            });
        }
    },
};

</script>


<style scoped>

</style>
